// Here you can add other styles
/* Add this to your main CSS/SCSS file */

/* Target the breadcrumb container */
.breadcrumb-container {
    height: 100%;
    display: flex;
    align-items: center;
  }
  
  /* Target reactstrap's Breadcrumb component */
  .breadcrumb-container .breadcrumb {
    margin: 0 !important;
    padding: 0 !important;
    background: transparent !important;
    border: none !important;
    border-radius: 0 !important;
    border-bottom: none !important;
    line-height: normal;
  }
  
  /* Target individual breadcrumb items */
  .breadcrumb-container .breadcrumb-item {
    display: inline-flex;
    align-items: center;
    padding: 0;
  }
  
  /* Style the separator between breadcrumb items */
  .breadcrumb-container .breadcrumb-item + .breadcrumb-item::before {
    padding: 0 0.5rem;
  }